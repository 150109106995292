import { getLodop } from "@/assets/LodopFuncs";
import JsBarcode from "jsbarcode";

export default function printLabel(items, labelSize) {
  const LODOP = getLodop();
  LODOP.PRINT_INIT("Location Label");
  LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
  LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

  if (labelSize === "length_95_width_43") {
    LODOP.SET_PRINT_PAGESIZE(1, "95mm", "43mm");
  }

  for (const item of items) {
    print_length_95_width_43_label(LODOP, item);
  }

  LODOP.PREVIEW();
}

function print_length_95_width_43_label(LODOP, item) {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, item.number, {
    format: "CODE128",
    width: 4,
    displayValue: true,
    background: "#ffffff",
    lineColor: "#000000",
  });
  const barcodeBase64 = canvas.toDataURL();
  const htmlText = `
  <div style="padding: 10%;">
    <div>
      <img src="${barcodeBase64}" style="width: 100%; height: 100%" />
    </div>
  </div>
`;

  LODOP.NewPage();
  LODOP.ADD_PRINT_HTM("0%", "0%", "100%", "100%", htmlText);
}
